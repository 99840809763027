registerProcessor(
  'RandomGateProcessor',
  class RandomGateProcessor extends AudioWorkletProcessor {
    static get parameterDescriptors() {
      return [
        {
          name: 'clock',
          defaultValue: 0,
          minValue: -1,
          maxValue: 1,
          automationRate: 'k-rate',
        },
        {
          name: 'probability',
          defaultValue: 0,
          minValue: 0,
          maxValue: 1,
          automationRate: 'k-rate',
        },
      ];
    }

    lastClock = 0;

    process(
      _: Float32Array[][],
      [[output]]: Float32Array[][],
      {
        clock: [clock],
        probability: [probability],
      }: { [param: string]: Float32Array },
    ): boolean {
      if (this.lastClock <= 0 && clock > 0) {
        this.port.postMessage(['trigger']);
        if (Math.random() < probability) {
          output[0] = 1;
        }
      }
      this.lastClock = clock;

      return true;
    }
  },
);
